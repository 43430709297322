/*

body > rootContainer > nodeContainer >  decisionContainer
                                        >   questionTextContainer
                                        >   choicesContainer
                                            > choice
                                                > image
                                                > spacer
                                                > choiceLabel
                                     >  solutionContainer


*/
body,
html,
#root,
.fui-FluentProvider {
    margin: 0;
    height: 100%;
    width: 100%;
}

.nodeContainer {
    flex: 0 0 100%;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    overflow: auto;
}

.rootContainer {
    display: flex;
    height: 100%;
    width: 100%;
    font-size: larger;
    font-family: "Chalkboard SE", "Comic Sans MS", "Comic Sans", cursive;
}

.decisionContainer {
    display: flex;
    flex: 0 0 auto;
    align-items: safe center;
    gap: 10px;
    width: 100%;
    flex-direction: column;
    height: 100%;
}

.innerWrapper {
    margin: auto;
}

.questionTextContainer {
    display: flex;
    gap: 5px;
    flex: 0 0 auto;
    flex-direction: column;
}

.questionContainer {
    flex: 0 0 auto;
    display: flex;
    gap: 5px;
    justify-content: center;
    margin: 10px;
}

.backButton {
    flex: 0 0 40px;
}

.spacer {
    flex: 1 1 100%;
}

.choiceLabel {
    flex: 0 0 auto;
    align-self: center;
}

.choicesContainer {
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-around;
}

.choice {
    display: flex;
    border: 5px solid whitesmoke;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    max-width: 90%;
}

.choice:hover {
    border-color: black;
}

@media only screen and (max-width: 600px) {
    .nodeContainer {
        align-items: initial;
    }
}

.solututionContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
}

.choiceContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image {
    flex: 0 0 auto;
    width: 300px;
    max-width: 100%;
    height: 290px;
    object-fit: contain;
}